//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Activation',
  data() {
    return {
      unlockCode: "",
      activation_key: this.$route.params.activation_key,
      isResending: false,
      isActivating: false,
      deadlockCount: 0,
      success: false
    }
  },
  metaInfo(){
        return {
            title:'Activate Account'
        }
    },
  methods: {
    activate() {
      if (this.unlockCode == "") {
        this.$q.dialog({
          title: 'Enter Unlock Code',
          message: 'Please enter the 6-digit unlock code. The code can be found on the email we sent for activation.'
        }).onOk(() => {});
        return;
      }
      let context = this;
      this.isActivating = true;
      this.isResending = false;

      this.$http({
        method: 'post',
        url: 'activator/index',
        data: {
          activation_key: this.activation_key,
          unlock_code: this.unlockCode
        }
      })
      .then((response) => {
        context.isActivating = false;
        context.isResending = false;
          if (response.data['status'] == 'invalid_activation_key') {
            context.$q.notify({
              message: 'Please try to log in again.',
              color: 'danger' 
            });
            context.$router.push('/login');
          }
          if (response.data['status'] == 'invalid_unlock_code') {
            context.$q.notify({
              message: 'The 6-digit activation code that you entered is incorrect. Please try again.',
              color: 'primary' 
            });
            this.deadlockCount += 1;
            if (this.deadlockCount == 5) {
              this.$q.notify({
                message: 'You have reached the maximum 5 attempts. Please try to login again and resend the code.',
                color: 'danger'
              });
              context.$router.push('/login');
            }
          }
          if (response.data['status'] == 'activation_success') {
            this.success = true;
            this.isActivating = false;
          }
      }).catch((err) => {
        if (err.response.status == 400) {
          this.$q.notify({
              message: 'There has been a mistake. Please try to login again.',
              color: 'danger'
          });
          context.$router.push('/login');
        }
      });
    },
    resend() {
      if (this.$route.params.activation_key === undefined || this.$route.params.activation_key == "") {
        context.$store.commit('logout');
        context.$router.push('/login');
        context.$q.notify({
          message: 'Please log in again to continue activating your account.',
          color: 'danger' 
        });  
        return;
      }
      let context = this;
      this.isResending = true;
      this.isActivating = false;
      this.$http({
        method: 'post',
        url: 'activator/resend',
        data: {
          activation_key: this.activation_key
        }
      })
      .then((response) => {
         context.isResending = false;
         context.isActivating = false;
          if (response.data['status'] == 'invalid_activation') {
            context.$store.commit('logout');
            context.$router.push('/login');
            context.$q.notify({
              message: 'Please log in again to continue activating your account.',
              color: 'primary' 
            });
            return;
          }
          if (response.data['status'] == 'email_send_failed') {
            context.$q.notify({
              message: 'There was an error while trying resend the email. Please try again later. ',
              color: 'danger' 
            });
            return;
          }
          if (response.data['status'] == 'email_interval') {
            if (response.data['body']['rem_mins'] == 0) {
            context.$q.notify({
              message: 'Please wait a couple more seconds before resending..',
              color: 'primary' 
            });
            return;
            } else {
            context.$q.notify({
              message: 'Please try resending the email again after ' + response.data['body']['rem_mins'] +' minute(s).',
              color: 'primary' 
            });
            return;
            }

          }
          if (response.data['status'] == 'resend_successful') {
            this.$q.dialog({
              title: 'Email Resent',
              message: 'The activation email has been successfully resent to your email address. Please check your inbox.'
            }).onOk(() => {
              //
            });
            return;
          }
      }).catch();
    },

    isLoggedIn(){
      if(this.$store.state.isLoggedIn){ this.$router.push('/home')}
    }
  },
  created(){
      this.isLoggedIn()
  },
  mounted() {
    if (this.activation_key === undefined || this.activation_key == "") {
      this.$store.commit('logout');
      this.$router.push('/login');
      this.$q.notify({
        message: 'Please log in again to continue activating your account.',
        color: 'danger' 
      });  
      return;
    }
  }
}
